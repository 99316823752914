<template>
  <div class="component-wrapper video d-flex pa-5">
    <v-progress-circular
      class="ma-auto"
      size="50"
      indeterminate
      color="primary"
      v-if="loader"
    ></v-progress-circular>

    <vueVimeoPlayer
      ref="player"
      :video-url="roomContent.openedContent"
      :controls="false"
      :options="{ muted: !roomContent.hasSound }"
      v-show="!loader"
      @ready="onLoadVideo"
      @playing="onPlaying"
      @pause="onPause"
      :key="roomContent.openedContent"
    />
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: { vueVimeoPlayer },
  computed: {
    ...mapState({
      roomContent: (state) => state.school.roomContent,
      allowLeave: (state) => state.school.allowLeave,
    }),
    loader() {
      return (
        !this.roomContent.schoolVideoLoaded ||
        !this.roomContent.guideVideoLoaded
      );
    },
  },
  methods: {
    ...mapActions(["loadVideo"]),
    ...mapMutations(["setAllowLeave"]),
    onLoadVideo() {
      this.$refs.player.play();
    },
    onPlaying() {
      if (!this.roomContent.schoolVideoLoaded) {
        this.$refs.player.pause();
      }
    },
    async onPause() {
      if (!this.roomContent.schoolVideoLoaded) {
        await this.loadVideo();
      }
    },
  },
  watch: {
    roomContent(newValue) {
      if (
        newValue.openedContentType == "video" &&
        newValue.guideVideoLoaded &&
        newValue.schoolVideoLoaded
      ) {
        if (newValue.videoPlaying) {
          this.$refs.player.play();
        } else {
          this.$refs.player.pause();
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.allowLeave) {
      this.setAllowLeave(false);
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>