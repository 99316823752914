<template>
  <div class="white d-flex flex-column align-center pt-1 pb-5">
    <v-btn icon class="align-self-end mr-1" @click="$emit('close')">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div class="inner d-flex flex-column">
      <div class="text-h6 mb-3">Κάμερα</div>

      <div
        class="test-local align-self-center mb-8"
        ref="testLocal"
        v-show="!videoErrorMsg"
      ></div>

      <v-alert dense text type="warning" class="mb-8" v-if="videoErrorMsg">
        {{ videoErrorMsg }}
      </v-alert>

      <div class="text-h6 mb-3">Μικρόφωνο</div>

      <div class="d-flex align-center" v-if="!audioErrorMsg">
        <div
          class="bullet"
          :class="{ primary: x <= audioLevel }"
          v-for="x in 10"
          :key="x"
        ></div>
      </div>

      <v-alert dense text type="warning" v-else>
        {{ audioErrorMsg }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import pollAudioLevel from "@/pollaudiolevel";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      audioTrack: null,
      audioLevel: 0,
      audioErrorMsg: null,
      videoTrack: null,
      videoErrorMsg: null,
    };
  },
  async created() {
    try {
      this.audioTrack = await this.getMicrophoneTrack();

      pollAudioLevel(this.audioTrack, (level) => {
        this.audioLevel = level;
      });
    } catch (e) {
      this.audioErrorMsg = await this.handleTrackError({
        type: "audio",
        error: e.name,
      });
    }
  },
  async mounted() {
    try {
      this.videoTrack = await this.getCameraTrack();

      this.$refs.testLocal.appendChild(this.videoTrack.attach());
    } catch (e) {
      this.videoErrorMsg = await this.handleTrackError({
        type: "video",
        error: e.name,
      });
    }
  },
  methods: {
    ...mapActions(["getCameraTrack", "getMicrophoneTrack", "handleTrackError"]),
  },
  destroyed() {
    this.audioTrack?.stop();
    this.videoTrack?.stop();
  },
};
</script>

<style lang="scss" scoped>
.inner {
  width: 640px;
}

.bullet {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid black;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
}
</style>