<template>
  <div class="component-wrapper d-flex flex-column align-center pa-5">
    <img class="logo mb-4" src="@/assets/logo.svg" @click="onLogoClick" />

    <form
      @submit.prevent="submit"
      class="d-flex flex-column mt-auto mb-6 form"
      novalidate
      autocomplete="off"
    >
      <v-text-field
        label="Κωδικός προγράμματος"
        outlined
        class="flex-grow-0 mb-2"
        dense
        type="email"
        v-model="roomName"
        color="primary"
        :error-messages="roomNameErrors"
      ></v-text-field>

      <v-btn
        color="primary"
        class="align-self-center"
        depressed
        type="submit"
        :loading="loader"
        :disabled="!isSupported"
        >Έναρξη προγράμματος</v-btn
      >
    </form>

    <v-btn @click="dialog = true" class="mb-6" :disabled="!isSupported">
      Έλεγχος εικόνας και ήχου
    </v-btn>

    <v-alert dense text type="error" v-if="!isSupported">
      Το πρόγραμμα περιήγησής σας (Web Browser) δεν υποστηρίζει το πρωτοκολλο
      που χρησιμοποιείται για την τηλε-παρουσίαση
    </v-alert>

    <v-alert dense text type="warning" v-if="cameraPermission == 'denied'">
      Δεν έχετε δώσει άδεια για χρήση της κάμερας
    </v-alert>

    <v-alert dense text type="warning" v-if="micPermission == 'denied'">
      Δεν έχετε δώσει άδεια για χρήση του μικροφώνου
    </v-alert>

    <div class="body-2 mt-auto">
      Δείτε μια λίστα
      <a class="font-weight-medium text-decoration-underline primary--text"
        >συχνών προβλημάτων</a
      >
      και αν δεν το βρείτε σε αυτή επικοινωνήστε με την τεχνική υποστήριξη στο
      <a class="font-weight-medium" href="mailto:support@museotek.net"
        >support@museotek.net</a
      >
    </div>

    <v-dialog v-model="dialog" max-width="700px">
      <CheckTracks v-if="dialog" @close="dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { isSupported } from "twilio-video";
import CheckTracks from "@/views/CheckTracks";

export default {
  components: {
    CheckTracks,
  },
  data() {
    return {
      roomName: "",
      loader: false,
      isSupported,
      dialog: false,
    };
  },
  validations: {
    roomName: { required },
  },
  computed: {
    ...mapState({
      cameraPermission: (state) => state.base.cameraPermission,
      micPermission: (state) => state.base.micPermission,
    }),
    roomNameErrors() {
      const errors = [];
      if (!this.$v.roomName.$dirty) return errors;
      if (!this.$v.roomName.required)
        errors.push("Αυτό το πεδίο είναι απαραίτητο");
      return errors;
    },
  },
  methods: {
    ...mapActions(["connectToRoom"]),
    onLogoClick() {
      window.open("http://www.museotek.net");
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loader = true;
        await this.connectToRoom(this.roomName);
        this.loader = false;
        this.$router.replace({ path: "/room" });
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 30px;
  cursor: pointer;
}

.form {
  width: 400px;
}
</style>
