<template>
  <div class="d-flex py-1 pl-4 pr-1 camera-buttons">
    <v-btn icon color="white" class="error mr-3" @click="dialog = true">
      <v-icon> mdi-phone-hangup </v-icon>
    </v-btn>

    <v-btn
      icon
      color="white"
      class="mr-3"
      :class="{ grey: Boolean(cameraError), primary: !Boolean(cameraError) }"
      :disabled="Boolean(cameraError)"
      @click="
        changeTrackState({
          kind: 'video',
          action: tracksMuted.video ? 'unmute' : 'mute',
        })
      "
    >
      <v-icon>
        {{ tracksMuted.video ? "mdi-video-off" : "mdi-video" }}
      </v-icon>
    </v-btn>

    <div class="mic-btn-wrapper">
      <div class="tooltip caption grey white--text" v-if="tracksMuted.audio">
        Σε σίγαση
      </div>

      <v-btn
        icon
        color="white"
        :class="{ grey: Boolean(micError), primary: !Boolean(micError) }"
        :disabled="Boolean(micError)"
        @click="
          changeTrackState({
            kind: 'audio',
            action: tracksMuted.audio ? 'unmute' : 'mute',
          })
        "
      >
        <v-icon>
          {{ tracksMuted.audio ? "mdi-microphone-off" : "mdi-microphone" }}
        </v-icon>
      </v-btn>
    </div>

    <div class="body-2 warning--text align-self-center ml-3">
      {{ micError }}
    </div>

    <v-btn
      icon
      color="white"
      class="primary ml-auto"
      @click="toggleChat(!chat)"
    >
      <v-icon>mdi-chat</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="500">
      <div class="white pa-3">
        <div class="body-1 text-center mb-4">
          Θέλετε να αποχωρήσετε από την παρουσίαση?
        </div>

        <div class="d-flex justify-center">
          <v-btn color="error" depressed class="mr-3" @click="onLeave"
            >Αποχωρηση</v-btn
          >

          <v-btn color="primary" depressed @click="dialog = false"
            >Παραμονη</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      tracksMuted: (state) => state.twilio.tracksMuted,
      cameraError: (state) => state.twilio.cameraError,
      micError: (state) => state.twilio.micError,
      chat: (state) => state.base.chat,
    }),
  },
  methods: {
    ...mapMutations(["toggleChat", "setAllowLeave"]),
    ...mapActions(["changeTrackState"]),
    onLeave() {
      this.setAllowLeave(true);
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.camera-buttons {
  border-top: 1px solid #e0e0e0;
}

.mic-btn-wrapper {
  position: relative;

  .tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1px 8px;
    border-radius: 3px;
    white-space: nowrap;
  }
}
</style>
