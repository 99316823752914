<template>
  <div class="component-wrapper d-flex pa-5">
    <v-progress-circular
      class="ma-auto"
      size="50"
      indeterminate
      color="primary"
      v-if="loader"
    ></v-progress-circular>

    <div class="image-wrapper flex-grow-1" v-show="!loader">
      <img
        :src="`${baseUrl}/${roomContent.openedContent}`"
        @load="onLoadImage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      loader: true,
    };
  },
  computed: {
    ...mapState({
      roomContent: (state) => state.school.roomContent,
      allowLeave: (state) => state.school.allowLeave,
    }),
  },
  methods: {
    ...mapActions(["loadImage"]),
    ...mapMutations(["setAllowLeave"]),
    async onLoadImage() {
      this.loader = true;

      await this.loadImage();
      this.loader = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.allowLeave) {
      this.setAllowLeave(false);
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  position: relative;
}

img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>