import { render, staticRenderFns } from "./ContentWrapper.vue?vue&type=template&id=dd570b86&scoped=true&"
import script from "./ContentWrapper.vue?vue&type=script&lang=js&"
export * from "./ContentWrapper.vue?vue&type=script&lang=js&"
import style0 from "./ContentWrapper.vue?vue&type=style&index=0&id=dd570b86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd570b86",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
