<template>
  <div class="component-wrapper d-flex flex-column">
    <Header />

    <div class="local elevation-5 rounded white" ref="local">
      <div
        class="track-msg white--text body-1 text-center"
        v-if="tracksMuted.video"
      >
        Η κάμερά σας είναι απενεργοποιημένη
      </div>

      <div
        class="track-msg body-2 warning--text text-center"
        v-if="Boolean(cameraError)"
      >
        {{ cameraError }}
      </div>
    </div>

    <div class="remote" ref="remote">
      <div class="track-msg body-1 text-center" v-if="!remoteConnected">
        Ο υπεύθυνος του προγράμματος δεν έχει συνδεθεί. Παρακαλούμε περιμένετε!
      </div>

      <div
        class="track-msg black body-1 text-center white--text"
        v-if="!remoteVideoState && remoteConnected"
      >
        Ο υπεύθυνος του προγράμματος έχει κλείσει προσωρινά την κάμερα του
      </div>
    </div>

    <Buttons />
  </div>
</template>

<script>
import Header from "@/views/Header";
import Buttons from "@/views/Buttons";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Header,
    Buttons,
  },
  computed: {
    ...mapState({
      triggersetListeners: (state) => state.twilio.triggersetListeners,
      remoteConnected: (state) => state.twilio.remoteConnected,
      remoteVideoState: (state) => state.twilio.remoteVideoState,
      tracksMuted: (state) => state.twilio.tracksMuted,
      allowLeave: (state) => state.school.allowLeave,
      cameraError: (state) => state.twilio.cameraError,
    }),
  },
  mounted() {
    this.changeRefs({
      local: this.$refs.local,
      remote: this.$refs.remote,
    });

    if (!this.triggersetListeners) this.setTriggersetListeners();
  },
  methods: {
    ...mapActions(["changeRefs", "detachTracks"]),
    ...mapMutations(["setTriggersetListeners", "setAllowLeave"]),
  },
  destroyed() {
    this.detachTracks();
  },
  beforeRouteLeave(to, from, next) {
    if (this.allowLeave) {
      this.setAllowLeave(false);
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.local {
  position: absolute;
  top: calc(53px + 20px); //53px is the header height.
  left: 16px;
  width: 160px;
  height: 120px;
  z-index: 1;
  overflow: hidden;
}

.remote {
  height: 1px;
  flex-grow: 1;
}
</style>