export default {
    state: {
        snackbar: {
            open: false,
            text: null,
            color: null
        },
        chat: false,
        cameraPermission: null,
        micPermission: null
    },
    mutations: {
        toggleSnackbar(state, snackbar) {
            state.snackbar = {
                ...state.snackbar,
                ...snackbar
            };
        },
        toggleChat(state, chat) {
            state.chat = chat;
        },
        queryCameraPermission(state, cameraPermission) {
            state.cameraPermission = cameraPermission;
        },
        queryMicPermission(state, micPermission) {
            state.micPermission = micPermission;
        }
    },
    actions: {
        async queryCameraPermission({ commit }) {
            if ("permissions" in navigator) {
                const cameraPermission = await navigator.permissions.query({
                    name: "camera",
                });

                commit("queryCameraPermission", cameraPermission.state);
            }
        },
        async queryMicPermission({ commit }) {
            if ("permissions" in navigator) {
                const micPermission = await navigator.permissions.query({
                    name: "microphone",
                });

                commit("queryMicPermission", micPermission.state);
            }
        }
    },
}