import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import Home from '../views/Home.vue'
import Room from '../views/Room.vue'
import Camera from '../views/Camera.vue'
import ContentWrapper from '../views/ContentWrapper.vue'
import Image from '../views/Image.vue'
import Video from '../views/Video.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location)
    .catch(err => {
      if (err.name != "NavigationDuplicated") {
        throw err;
      }
    })
};

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/room',
    component: Room,
    beforeEnter(to, from, next) {
      if (store.state.twilio.room) {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: "/",
        redirect: 'camera'
      },
      {
        path: 'camera',
        component: Camera
      },
      {
        path: 'content',
        component: ContentWrapper,
        children: [
          {
            path: "/",
            redirect: '/room/camera'
          },
          {
            path: 'image',
            component: Image
          },
          {
            path: 'video',
            component: Video
          },
        ]
      }
    ]
  },
  {
    path: "*",
    redirect: '/room/camera'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
