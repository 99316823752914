<template>
  <div class="component-wrapper d-flex flex-column">
    <Header />

    <div class="d-flex flex-grow-1">
      <div class="left d-flex flex-column">
        <div class="half local" ref="local">
          <div
            class="track-msg white--text body-1 text-center"
            v-if="tracksMuted.video"
          >
            Η κάμερά σας είναι απενεργοποιημένη
          </div>

          <div
            class="track-msg body-2 warning--text text-center"
            v-if="Boolean(cameraError)"
          >
            {{ cameraError }}
          </div>
        </div>

        <v-divider></v-divider>

        <div class="half remote" ref="remote">
          <div
            class="track-msg body-1 text-center white--text"
            v-if="!remoteVideoState && remoteConnected"
          >
            Ο υπεύθυνος του προγράμματος έχει κλείσει προσωρινά την κάμερα του
          </div>
        </div>
      </div>

      <div class="right d-flex">
        <router-view></router-view>
      </div>
    </div>
    <Buttons />
  </div>
</template>

<script>
import Header from "@/views/Header";
import Buttons from "@/views/Buttons";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  components: {
    Header,
    Buttons,
  },
  computed: {
    ...mapState({
      triggersetListeners: (state) => state.twilio.triggersetListeners,
      remoteConnected: (state) => state.twilio.remoteConnected,
      remoteVideoState: (state) => state.twilio.remoteVideoState,
      tracksMuted: (state) => state.twilio.tracksMuted,
      cameraError: (state) => state.twilio.cameraError,
    }),
  },
  mounted() {
    this.changeRefs({
      local: this.$refs.local,
      remote: this.$refs.remote,
    });

    if (!this.triggersetListeners) this.setTriggersetListeners();
  },
  methods: {
    ...mapActions(["changeRefs", "detachTracks"]),
    ...mapMutations(["setTriggersetListeners"]),
  },
  destroyed() {
    this.detachTracks();
  },
};
</script>

<style lang="scss" scoped>
.left {
  width: 20%;
  border-right: 1px solid #e0e0e0;
  flex-shrink: 0;

  .half {
    height: 50%;
  }
}

.right {
  flex: 1;
}
</style>