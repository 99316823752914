import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.filter('minuteSeconds', (value) => {
  const minutes = Math.floor(value / 60);
  return (
    minutes.toString().padStart(2, "0") +
    ":" +
    (value - minutes * 60).toString().padStart(2, "0")
  );
});