import twilio from "twilio-video";
import router from "@/router";

export default {
    state: {
        localTracks: null,
        cameraError: null,
        micError: null,
        room: null,
        roomCreatedAt: null,
        triggersetListeners: false,
        localRef: null,
        remoteRef: null,
        remoteConnected: false,
        remoteVideoState: false,
        tracksMuted: {
            video: false,
            audio: false
        }
    },
    mutations: {
        setLocalTracks(state, localTracks) {
            state.localTracks = localTracks;
        },
        setCameraError(state, cameraError) {
            state.cameraError = cameraError;
        },
        setMicError(state, micError) {
            state.micError = micError;
        },
        setRoom(state, room) {
            state.room = room;
        },
        setRoomCreatedAt(state, roomCreatedAt) {
            state.roomCreatedAt = roomCreatedAt;
        },
        setTriggersetListeners(state) {
            state.triggersetListeners = true;
        },
        setRefs(state, refs) {
            state.localRef = refs.local;
            state.remoteRef = refs.remote;
        },
        setRemoteConnected(state, connected = true) {
            state.remoteConnected = connected;
        },
        setRemoteVideoState(state, remoteVideoState) {
            state.remoteVideoState = remoteVideoState;
        },
        changeTrackState(state, params) {
            state.tracksMuted[params.kind] = params.action == 'mute' ? true : false;
        },
        resetTwilioState(state) {
            state.localTracks = null;
            state.cameraError = null;
            state.micError = null;
            state.room = null;
            state.roomCreatedAt = null;
            state.triggersetListeners = false;
            state.localRef = null;
            state.remoteRef = null;
            state.remoteConnected = false;
            state.remoteVideoState = false;
            state.tracksMuted = {
                video: false,
                audio: false
            };
        }
    },
    actions: {
        async getCameraTrack() {
            try {
                const videoTrack = await twilio.createLocalVideoTrack({
                    height: 1080,
                    width: 1920,
                    frameRate: 60,
                });

                return videoTrack;
            } catch (e) {
                throw e;
            }
        },
        async getMicrophoneTrack() {
            try {
                const audioTrack = await twilio.createLocalAudioTrack();

                return audioTrack;
            } catch (e) {
                throw e;
            }
        },
        handleTrackError({ dispatch }, { type, error }) {
            if (error == 'NotFoundError') {
                return `Δεν βρέθηκε ${type == 'video' ? 'συνδεδεμένη κάμερα' : 'συνδεδεμένο μικρόφωνο'}`
            } else if (error == "NotAllowedError") {
                if (type == 'video') {
                    dispatch("queryCameraPermission");
                } else {
                    dispatch("queryMicPermission");
                }

                return `Δεν έχετε δώσει άδεια για χρήση ${type == 'video' ? 'της κάμερας' : 'του μικροφώνου'}`
            } else {
                return ` Δεν καταφέραμε να εντοπίσουμε ${type == 'video' ? 'την κάμερα' : 'το μικρόφωνο'}`
            }
        },
        async connectToRoom({ commit, dispatch }, roomName) {
            try {
                const roomInfo = await dispatch('getContent', roomName);

                let videoTrack;
                let audioTrack;
                let tracks = [];

                try {
                    videoTrack = await dispatch("getCameraTrack");
                } catch (e) {
                    const videoErrorMsg = await dispatch("handleTrackError", {
                        type: "video",
                        error: e.name,
                    });

                    commit("setCameraError", videoErrorMsg);
                }

                try {
                    audioTrack = await dispatch("getMicrophoneTrack");
                } catch (e) {
                    const audioErrorMsg = await dispatch("handleTrackError", {
                        type: "audio",
                        error: e.name,
                    });

                    commit("setMicError", audioErrorMsg);
                }

                if (videoTrack) {
                    tracks.push(videoTrack);
                }

                if (audioTrack) {
                    tracks.push(audioTrack);
                }

                if (tracks.length == 0) {
                    tracks = await twilio.createLocalTracks({
                        audio: false,
                        video: false
                    })
                }

                commit('setLocalTracks', tracks);

                const room = await twilio.connect(roomInfo.accessToken, { tracks });
                commit('setRoom', room);
                commit('setRoomCreatedAt', roomInfo.roomCreatedAt);

                await dispatch("bindRoomContent");
            } catch (e) {
                if (e?.response?.status == 400) {
                    if (e.response.data.data.program) {
                        commit("toggleSnackbar", {
                            open: true,
                            color: "error",
                            text: 'Δεν υπάρχει ενεργό πρόγραμμα με τον κωδικό εισιτηρίου που εισάγατε ή το πρόγραμμα δεν έχει ξεκινήσει ακόμα. Σας θυμίζουμε ότι μπορείτε να συνδεθείτε το νωρίτερο 10 λεπτά πριν την έναρξη ενός προγράμματος.'
                        })
                    }
                }
                // else if (e?.response?.status == 403) {
                //     commit("toggleSnackbar", {
                //         open: true,
                //         color: "error",
                //         text: 'Δεν υπάρχει προγραμματισμενή συνεδρία με αυτόν τον κωδικό αυτην την στιγμή. Δοκιμάστε ξανά αργότερα.'
                //     })
                // }
                else {
                    commit("toggleSnackbar", {
                        open: true,
                        color: "error",
                        text: 'Δεν μπορέσαμε να σας συνδέσουμε στην παρουσίαση. Δοκιμάστε άλλον κωδικό η δοκιμάστε ξανά αργότερα.'
                    })
                }

                throw e;
            }
        },
        setListeners({ state, commit }) {
            //====== HANDLE CONNECTED PARTICIPANTS ======
            state.room.participants.forEach((participant) => {
                if (participant.identity == 'guide') {
                    participant.on("trackSubscribed", (track) => {
                        state.remoteRef.appendChild(track.attach());

                        if (track.kind == 'video') {
                            commit('setRemoteVideoState', track.isEnabled)
                        }
                    });

                    participant.on("trackEnabled", (publication) => {
                        if (publication.kind == 'video') {
                            commit('setRemoteVideoState', publication.isTrackEnabled)
                        }
                    });

                    participant.on("trackDisabled", (publication) => {
                        if (publication.kind == 'video') {
                            commit('setRemoteVideoState', publication.isTrackEnabled)
                        }
                    });

                    commit("setRemoteConnected");
                }
            });

            //====== HANDLE REMOTE PARTICIPANTS EVENTS ======
            //------ participant connected ------

            state.room.on("participantConnected", (participant) => {
                console.log(`Participant "${participant.identity}" connected`);

                if (participant.identity == 'guide') {
                    participant.tracks.forEach((publication) => {
                        if (publication.isSubscribed) {
                            if (publication.kind == 'video') {
                                commit('setRemoteVideoState', publication.isTrackEnabled)
                            }

                            const track = publication.track;
                            state.remoteRef.appendChild(track.attach());
                        }
                    });

                    participant.on("trackSubscribed", (track) => {
                        if (track.kind == 'video') {
                            commit('setRemoteVideoState', track.isEnabled)
                        }

                        state.remoteRef.appendChild(track.attach());
                    });

                    participant.on("trackDisabled", (publication) => {
                        if (publication.kind == 'video') {
                            commit('setRemoteVideoState', publication.isTrackEnabled)
                        }
                    });

                    participant.on("trackEnabled", (publication) => {
                        if (publication.kind == 'video') {
                            commit('setRemoteVideoState', publication.isTrackEnabled)
                        }
                    });

                    commit("setRemoteConnected");
                }
            });

            //------ participant disconnected ------
            state.room.on("participantDisconnected", (participant) => {
                console.log(`Participant disconnected: ${participant.identity}`);

                if (participant.identity == 'guide') {
                    const video = state.remoteRef.getElementsByTagName("video")[0];
                    const audio = state.remoteRef.getElementsByTagName("audio")[0];

                    video.pause();
                    audio.pause();
                    state.remoteRef.removeChild(video);
                    state.remoteRef.removeChild(audio);

                    commit("setRemoteConnected", false);
                }
            });

            state.room.on("disconnected", room => {
                commit("setAllowLeave", true);

                state.localTracks.forEach((track) => {
                    track.stop();
                });

                router.push({ path: "/" });
            })
        },
        changeRefs({ commit, dispatch }, refs) {
            commit('setRefs', refs);
            dispatch('attachTracks');
        },
        attachTracks({ commit, state }) {
            state.room.localParticipant.videoTracks.forEach((publication) => {
                state.localRef.appendChild(publication.track.attach());
            });

            state.room.participants.forEach((participant) => {
                console.log(`Participant "${participant.identity}" already connected`);

                if (participant.identity == 'guide') {
                    participant.tracks.forEach((publication) => {
                        if (publication.isSubscribed) {
                            const track = publication.track;

                            state.remoteRef.appendChild(track.attach());

                            if (publication.kind == 'video') {
                                commit('setRemoteVideoState', publication.isTrackEnabled)
                            }
                        }
                    });

                    commit("setRemoteConnected");
                }
            })
        },
        detachTracks({ state }) {
            state.room.localParticipant.videoTracks.forEach((track) => {
                track.track.detach()
            });

            state.room.participants.forEach((participant) => {
                if (participant.identity == 'guide') {
                    participant.tracks.forEach((publication) => {
                        if (publication.isSubscribed) {
                            publication.track.detach();
                        }
                    });
                }
            });
        },
        changeTrackState({ state, commit }, params) {
            state.room.localParticipant.tracks.forEach((publication) => {
                if (publication.kind == params.kind) {
                    if (params.action == 'mute') {
                        publication.track.disable();
                    } else {
                        publication.track.enable();
                    }
                }
            })

            commit("changeTrackState", params);
        },
        disconnect({ state, commit, dispatch }) {
            state.room.disconnect();

            state.localTracks.forEach((track) => {
                track.stop();
            });

            commit("resetTwilioState");
            commit("resetSchoolState");

            dispatch("unbindRoomContent");
        }
    },
}
