<template>
  <v-app id="museotek">
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-snackbar v-model="snackbar.open" :color="snackbar.color" timeout="5000">
      <div class="d-flex align-center">
        <div class="body-2 mr-2">{{ snackbar.text }}</div>
        <v-btn text small class="ml-auto" @click="onCloseSnackbar"
          >Κλείσιμο</v-btn
        >
      </div>
    </v-snackbar>

    <Chat v-if="room" />
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Chat from "@/views/Chat";

export default {
  components: {
    Chat,
  },
  computed: {
    ...mapState({
      snackbar: (state) => state.base.snackbar,
      room: (state) => state.twilio.room,
    }),
  },
  async created() {
    window.addEventListener("beforeunload", () => {
      if (this.room?.state == "connected") this.room?.disconnect();
    });

    this.queryCameraPermission();
    this.queryMicPermission();
  },
  methods: {
    ...mapMutations(["toggleSnackbar"]),
    ...mapActions(["queryCameraPermission", "queryMicPermission"]),
    onCloseSnackbar() {
      this.toggleSnackbar({
        open: false,
      });
    },
  },
};
</script>

<style lang="scss">
//====== VUETIFY OVERRIDES ======

#museotek.v-application {
  *:not(.v-icon),
  *:not(.v-icon):before,
  *:not(.v-icon):after {
    font-family: "Open Sans", sans-serif !important;
  }

  .text-h6,
  .font-weight-medium,
  .v-btn {
    font-weight: 600 !important;
  }
}

.v-main__wrap {
  display: flex;
}

.v-btn.v-size--small {
  .v-progress-circular {
    height: 19px !important;
    width: 19px !important;
  }
}

.v-divider {
  border-color: #e0e0e0 !important;
}

.v-btn:not(.v-btn--icon) {
  letter-spacing: normal !important;
  text-transform: none !important;
  font-size: 14px !important;
  height: 33px !important;
  border-radius: 2px !important;
}

//====== GLOBAL STYLES ======
.component-wrapper {
  flex-grow: 1;
  position: relative;

  &.video > div {
    flex: 1;
    display: flex;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.local,
.remote {
  position: relative;

  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &.half {
    video {
      object-fit: contain;
    }
  }
}

.track-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  padding: 0px 5px 0 5px;
}
</style>
